import { LineItem as CartLineItem } from "../../components/cart/LineItem";

const Cart = createReactClass({
  statics: {
    close() {
      return UIkit.offcanvas.hide();
    }
  },

  getInitialState() {
    return {initialized: false};
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.initialized || !("order" in nextProps)) { return; }
    return this.setState({initialized: true});
  },

  render() {
    let content;
    const empty = !this.props.order || (this.props.order.attributes.line_items.length === 0);

    if (empty) {
      content = <Empty initialized={this.state.initialized}/>;
    } else {
      content = <Full order={this.props.order} lineItems={this.props.order.lineItems}/>;
    }

    return <div className="uk-offcanvas" id="pw-cart">
      <div className="uk-offcanvas-bar uk-offcanvas-bar-flip">
        <div className="uk-flex uk-flex-column uk-height-1-1">
          <TopNav />

          {content}
        </div>
        </div>
    </div>;
  }
});


var Full = createReactClass({
  render() {
    return <div className="uk-flex-item-1 uk-flex uk-flex-column" style={{minHeight: "0"}}>
      <div className="uk-flex-item-1 uk-flex uk-flex-column uk-overflow-container" style={{minHeight: "0"}}>
        <LineItems lineItems={this.props.lineItems}/>
      </div>
      <div className="uk-flex-item-none uk-background-material">
        <BottomNav order={this.props.order}/>
      </div>
    </div>;
  }
});


var LineItems = createReactClass({
  render() {
    const sortedItems = _.sortBy(this.props.lineItems, function(item) {
      const date = new Date(item.attributes.created_at);
      return date.getTime();
    });

    const items = sortedItems.map(item => <CartLineItem key={item.attributes.id} item={item}/>);

    return <div>
      {items}
    </div>;
  }
});


var Empty = createReactClass({
  render() {
    let content;
    if (!this.props.initialized) {
      content = <div className="loading-wine"></div>;
    } else {
      content = <div className="uk-text-blocked">Your cart is empty</div>;
    }

    return <div className="uk-flex uk-flex-middle uk-flex-center uk-flex-item-1" style={{background: "#ffffff"}}>
      {content}
    </div>;
  }
});


var TopNav = createReactClass({
  render() {
    return <div className="uk-flex-item-none uk-flex uk-flex-middle uk-flex-center uk-position-relative pw-top-nav">
      <div className="uk-text-large">Cart</div>

      <a href="#" onClick={Cart.close} className="uk-position-absolute uk-link-subtle pw-close" style={{padding: "0 30px"}}>
        <i className="far fa-chevron-left icon-medium"></i>
      </a>
    </div>;
  }
});


var BottomNav = createReactClass({
  handleSubmit(event) {
    event.preventDefault();
    return window.location.href = "/checkout";
  },

  render() {
    //    style = if @props.order.updating then {'pointerEvents': 'none', opacity: '.7'} else  {'opacity': '1'}
    return <div>
      <div style={{padding: "15px 20px"}}>
        <OrderTotalsTable className="uk-table uk-table-condensed uk-table-borderless"
                          order={this.props.order}/>

        <div style={{marginTop: "25px"}}>
          <CouponCodeForm {...this.props} />

          <form onSubmit={this.handleSubmit} className="pw-checkout-form">
            <UIKitButton className="uk-button-primary uk-button-large uk-width-1-1">
              Checkout
            </UIKitButton>
          </form>
        </div>
      </div>
    </div>;
  }
});


var CouponCodeForm = createReactClass({
  getInitialState() {
    return {inputValue: ""};
  },

  async handleSubmit(event) {
    event.preventDefault();
    if (this.state.inputValue.length) {
      await this.props.order.applyCouponCode(this.state.inputValue);
      return this.setState({inputValue: ""});
    }
  },

  handleChange(event) {
    return this.setState({inputValue: event.target.value});
  },

  render() {
    return <form className="uk-form uk-margin" onSubmit={this.handleSubmit}>
      <div className="uk-grid uk-grid-small">
        <div className="uk-width-7-10">
          <input type="text" value={this.state.inputValue} onChange={this.handleChange}
                  className="uk-form-small uk-width-1-1 "
                  name="coupon_code"
                  placeholder="Have a coupon code?"/>
        </div>
        <div className="uk-width-1-4" style={{paddingLeft: "0px", marginLeft: "5px"}}>
          <button className="uk-button uk-button-gray uk-button-small uk-width-1-1">Apply</button>
        </div>
      </div>
    </form>;
  }
});


const GiftCard = createReactClass({
  edit(event) {
    event.preventDefault();
    return Pervino.Routine.GiftMessage.launch(this.props.message, giftMessage => {
      return this.props.set(giftMessage);
    });
  },

  delete(event) {
    event.preventDefault();
    return this.props.set(null);
  },

  render() {
    if (this.props.message) { return false; }

    return <div style={{paddingTop: "9px"}}>
      <UIKitButton onClick={this.edit} className="uk-button-reverse uk-button-small uk-width-1-1">
        Add a personalized card - $3
      </UIKitButton>
    </div>;
  }
});

export default Cart;
