import { reportAddToCartEvent } from "./FacebookConversions"
import CouponCodes from "../../shared/api/spree/CouponCodes"

export class Order {
  static current = null

  static initialize(number, token) {
    if (number && token) {
      return Order.current = new OrderResource(number, token);
    } else {
      return PubSub.publish('app.order.updated', {order: null});
    }
  }

  static add(...args) {

    let req = null;

    if (Order.current) {
      req = Order.current.add(...Array.from(args || []));

      var quantity = args[1]
      req.done((data)=> {
        reportAddToCartEvent({
          sku: data.variant.sku,
          quantity: quantity,
          order_number: data.order.number
        })
      })
      return;
    } else {
      // Set up information needed for the subscription line item
      const today = new Date();
      const year = today.getFullYear() + 1;
      /* getMonth() returns 0-indexed months */
      const month = String(today.getMonth() + 1);
      const day = today.getDate() - 1;
      const endDate = `${year}/${month}/${day}`
      const variantId = args[0]["id"];
      const quantity = args[1];

      const subscriptionLineItem = (args[0]["subscribable"] == true) ? {
        quantity: quantity,
        subscribable_id: variantId,
        interval_length: 3, /* For now every subscription is 1-year long, shipping out every 3-months */
        interval_units: "month",
        end_date: endDate
      } : {};

      args[0]["subscription_line_item"] = subscriptionLineItem;

      req = Api.Pervino.Order.create_in_session(...Array.from(args || []));
      return req.done(function(data) {
        Order.current = new OrderResource(data.number, data.token, data);

        reportAddToCartEvent({
          sku: data.line_items[0].variant.sku,
          quantity: data.line_items[0].quantity,
          order_number: data.number
        })

        return PubSub.publish('app.order.updated', {order: Order.current});
      });
    }
  }

  static refresh() {
    if (Order.current) { return Order.current.refresh(); }
  }
}

class OrderResource {

  constructor(number, token, attributes) {
    this._setAttributes = this._setAttributes.bind(this);
    this._update = this._update.bind(this);
    this.refresh = this.refresh.bind(this);
    this.update = this.update.bind(this);
    this.advance = this.advance.bind(this);
    this.updateThenAdvance = this.updateThenAdvance.bind(this);
    this.complete = this.complete.bind(this);
    this.add = this.add.bind(this);
    this.applyCouponCode = this.applyCouponCode.bind(this);
    this.setGiftMessage = this.setGiftMessage.bind(this);
    this.availablePaymentMethods = this.availablePaymentMethods.bind(this);
    this.api = new Api.Pervino.Order(number, token);
    this.checkoutApi = new Api.Pervino.Checkout(number, token);

    if (attributes) {
      this._setAttributes(attributes);
    } else {
      this.refresh();
    }
  }


  _setAttributes(attributes) {
    this.attributes = attributes;
    return this.lineItems = _.map(attributes.line_items, lineItem => {
      return new LineItemResource(this, lineItem);
    });
  }

  _update(attributes) {
    this._setAttributes(attributes);
    return PubSub.publish('app.order.updated', {order: this});
  }


  refresh() {
    const req = this.api.refresh();
    return req.done(data => {
      return this._update(data);
    });
  }

  update(attributes) {
    const req = this.checkoutApi.update(attributes);
    return req.done(data => {
      return this._update(data);
    });
  }

  advance() {
    const req = this.checkoutApi.advance();
    return req.done(data => {
      return this._update(data);
    });
  }

  updateThenAdvance(attributes) {
    const req = this.checkoutApi.update(attributes);
    return req.done(data => {
      return this.advance();
    });
  }

  complete() {
    const req = this.checkoutApi.complete();
    return req.done(data => {
      return this._update(data);
    });
  }

  add(variant, quantity, options = {}, settings = {}) {
    const req = this.api.add(variant, quantity, options, settings);
    req.done(data => {
      return this._update(data.order);
    });
    return req;
  }

  async applyCouponCode(code) {
    Pervino.Util.Notifier.info('Loading...', {group: 'loading', timeout: 0});
    const response = await CouponCodes.create(this.attributes.number, this.attributes.token, code)
    Pervino.Util.Notifier.closeAll('loading', true);

    this.refresh()

    if(response.successful) {
      Pervino.Util.Notifier.success('Coupon code applied')
    } else {
      Pervino.Util.Notifier.danger(response.error)
    }
  }

  setGiftMessage(message, state = null) {
    const data = {gift_message: message};
    if (state) { data.state = state; }
    const req = this.api.simpleUpdate(data);
    return req.done(data => {
      return this._update(data);
    });
  }

  availablePaymentMethods(code) {
    const req = this.api.payments();
    return req.done(data => {
      return this._update(data);
    });
  }
}


class LineItemResource {

  constructor(order, attributes) {
    this._setAttributes = this._setAttributes.bind(this);
    this._update = this._update.bind(this);
    this.setQuantity = this.setQuantity.bind(this);
    this.setDesign = this.setDesign.bind(this);
    this.remove = this.remove.bind(this);
    this.order = order;
    this.api = new Api.Pervino.LineItem(attributes.id, order.attributes.number, order.attributes.token);
    this._setAttributes(attributes);
  }


  _setAttributes(attributes) {
    this.attributes = attributes;
    return this.customizations = _.map(attributes.customizations, customization => {
      return new CustomizationResource(this.order, this, customization);
    });
  }

  _update(attributes) {
    this._setAttributes(attributes);
    return PubSub.publish('app.order.updated', {order: this.order});
  }


  setQuantity(quantity) {
    const req = this.api.updateQuantity(quantity);

    return req.done(data => {
      this.order._update(data.order);

      if (quantity > this.attributes.quantity) {
        return PubSub.publish('order.add', {variant: this.attributes.variant, quantity: quantity - this.attributes.quantity});
      } else if (quantity < this.attributes.quantity) {
        return PubSub.publish('order.remove', {variant: this.attributes.variant, quantity: this.attributes.quantity - quantity});
      }
  });
  }

  setDesign(customization, design) {
    let req;
    return req = this.api.updateDesign(customization, design);
  }

  remove() {
    const req = this.api.remove();

    return req.done(() => {
      PubSub.publish('order.remove', {variant: this.attributes.variant, quantity: this.attributes.quantity});
      return this.order.refresh();
    });
  }
}


class CustomizationResource {

  constructor(order, lineItem, attributes) {
    this._setAttributes = this._setAttributes.bind(this);
    this._update = this._update.bind(this);
    this.setDesign = this.setDesign.bind(this);
    this.order = order;
    this.api = new Api.Spree.Customization(attributes.id, lineItem.attributes.id, order.attributes.number, order.attributes.token);
    this._lineItem = lineItem;
    this._setAttributes(attributes);
  }


  _setAttributes(attributes) {
    return this.attributes = attributes;
  }

  _update(attributes) {
    this._setAttributes(attributes);
    return PubSub.publish('app.order.updated', {order: this.order});
  }


  setDesign(article) {
    const req = this.api.update({customization: {
      article_id: article.id
    }
    });

    return req.done(data => {
      return this._update(data);
    });
  }
}


export class OrderComponent {
  constructor(klass, props, container) {
    this.setOrder = this.setOrder.bind(this);
    this._klass = klass;
    this._container = container;
    this._props = props || {};
    this._render();
  }

  setOrder(order) {
    this._props.order = order;
    return this._render();
  }

  _render() {
    return ReactDOM.render(React.createElement(this._klass, this._props), this._container);
  }
};
