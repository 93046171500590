export function getDimensionsFromSize(size) {
  let width;
  let height;

  switch(size) {
    case "bordeaux":
      width = 311;
      height = 400;
      break;
    case "teardrop":
      width = 400;
      height = 311;
      break;
    case "square":
    case "mini_square":
    case "custom_drink":
      width = 311;
      height = 311;
      break;
    case "veuve":
      width = 270;
      height = 72;
      break;
    case "woodbox_single":
    case "woodbox_photo_single":
      width = 270;
      height = 842;
      break;
    case "woodbox_double":
    case "woodbox_photo_double":
      width = 270;
      height = 394;
      break;
    case "woodbox_triple":
    case "woodbox_photo_triple":
      width = 270;
      height = 257;
      break;
    case "woodbox_magnum":
    case "woodbox_photo_magnum":
      width = 270;
      height = 915;
      break;
    case "gift_note":
      width = 1875;
      height = 1350;
      break;
    default:
      width = 311;
      height = 311;
      break;
  }

  return [width, height]
}
