function csrfToken(){
  return document.querySelector('meta[name=csrf-token]').attributes['content'].value
}

export function reportAddToCartEvent({sku, quantity, order_number}) {
  fetch('/facebook_conversions/report_add_to_cart_event', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({
      "product_sku": sku,
      "order_number": order_number,
      "quantity": quantity
    })
  });
}

export function reportInitiateCheckoutEvent() {
  fetch('/facebook_conversions/report_initiate_checkout_event', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    },
    body: JSON.stringify({})
  });
}
