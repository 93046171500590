export default class CouponCodes {
  static async create(order_number, order_token, coupon_code) {
    const response = await fetch(`/api/orders/${order_number}/coupon_codes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Spree-Order-Token": order_token
      },
      body: JSON.stringify({
        coupon_code: coupon_code
      })
    });

    return response.json()
  }
};
