import React, { useRef } from "react"
import { PortalWithState } from 'react-portal';
import { disableBodyScroll, enableBodyScroll } from "../shared/util/Modal";

export default function ImageLightbox({ imgTag, children, onOpen, onClose }){
  if(React.Children.count(imgTag) !== 1){
    throw "ImageLightbox can only recieve one element in the imgTag prop"
  }

  const imgElem = useRef(null)

  const handleClick = (event, closePortal) => {
    if(!imgElem.current.contains(event.target)){
      closePortal()
    }
  }

  return (
    <PortalWithState closeOnOutsideClick closeOnEsc onOpen={onOpen} onClose={onClose}>
    {({ openPortal, closePortal, isOpen, portal }) => {
      if(isOpen){
        disableBodyScroll()
      } else {
        enableBodyScroll()
      }

      return (
        <React.Fragment>
          <div onClick={openPortal}>
            {children}
          </div>
          {portal(
            <div className="lightbox-container" onClick={(event) => handleClick(event, closePortal)}>
              <div className="img-container">
                {React.cloneElement(imgTag, { ref: imgElem, className: "lightbox-img-tag" })}
                <a href="#" className="close-button uk-close uk-close-alt"></a>
              </div>
            </div>
          )}
        </React.Fragment>
      )
    }}
  </PortalWithState>
  )
}
