import { getDimensionsFromSize } from '../../shared/util/DesignSizes';
import ImageLightbox from '../ImageLightbox';
import ImageWithPlaceholder from '../ImageWithPlaceholder';

export const LineItem = createReactClass({
  getInitialState() {
    return {
      updating: false,
      localUpdates: {}
    };
  },

  componentWillReceiveProps(nextProps) {
    return this.setState({localUpdates: {}});
  },

  setQuantity(quantity) {
    this.setState({
      updating: true,
      localUpdates: {
        quantity
      }
    });

    const req = this.props.item.setQuantity(quantity);
    return req.always(() => {
      return this.setState({updating: false});
    });
  },

  remove() {
    this.setState({removing: true});
    return this.props.item.remove().fail(() => {
      return this.setState({removing: false});
    });
  },

  render() {
    let controls;
    const item = _.extend(this.props.item.attributes, this.state.localUpdates);

    const {
      available_design_configurations
    } = this.props.item.attributes;
    const {
      sku
    } = this.props.item.attributes.variant;
    const product_image = this.props.item.attributes.product_image_url;

    const customizations = this.props.item.customizations.map(customization => {
      return <LineItemCustomization key={customization.attributes.id} sku={sku} product_image={product_image} available_design_configurations={available_design_configurations} customization={customization.attributes} setDesign={customization.setDesign}/>;
    });

    if (!this.state.removing) {
      controls =
        <div className="uk-margin-top" style={{width: "100px"}}>
            <LineItemQuantity initialQuantity={item.quantity} update={this.setQuantity} updating={this.state.updating}/>
            <LineItemRemove remove={this.remove} removing={this.state.removing} updating={this.state.updating}/>
        </div>;
    }

    const style = this.state.removing ? {opacity: ".7", pointerEvents: 'none'} : {};
    const price = prettyPrice(item.total); // This will include the base price + the customization price if there is one
    let variant_details = "";
    if (item.variant.option_values.length) {
      const ov = item.variant.option_values[0];
      variant_details = <div><span className='uk-text-smaller'>{ov.option_type_presentation}:</span> <span>{ov.presentation}</span></div>;
    }

    return <div className="pw-line-item uk-padding-all" style={style}>
        <div className="uk-grid">
            <div className="uk-width-1-3 uk-text-center">
                <LineItemImage key={item.id} item={this.props.item}/>
            </div>
            <div className="uk-width-2-3 uk-margin-top">
                <div className="uk-text-darker uk-text-larger uk-text-500">{item.variant.name}</div>
                {variant_details}
                <div>{price}</div>

                {customizations}
                {controls}
            </div>
        </div>
    </div>;
  }
})

const LineItemCustomization = function({ sku, available_design_configurations, customization, setDesign }) {
  const handleClick = () => {
    const simple_check = customization.design.size.split(":").length;
    if (simple_check > 1) {
      let numberOfLines;
      const save = function(markup, url) {
        const design = {
          markup,
          full: url,
          size: customization.design.size,
          template_id: null,
          medium: "engraving",
          source_id: null
        };
        const req = Api.Pervino.Design.create(design, design);
        return req.done(design => setDesign(design));
      };

      const markup = JSON.parse(
        customization.design.markup
      );

      numberOfLines;

      if (markup.designType === "text") {
        numberOfLines = markup.config.availableLines;
      } else {
        numberOfLines = available_design_configurations[0].design_options[0].number_of_lines;
      }

      return designer.initSimple({
        onSave: save,
        lines: numberOfLines,
        sku: sku,
        markup
      });
    } else {
      return Pervino.Routine.SelectDesign.launch(customization.design.medium, customization.design.size, null, {sku: sku}, design => {
        return setDesign(design);
      });
    }
  }

  return (
    <div className="uk-margin" style={{fontStyle: 'italic'}}>
        + custom {customization.design.medium}
        &nbsp;
            <span>
              <div className='uk-display-inline-block'>
                <ImageLightbox
                  imgTag={
                    <ImageWithPlaceholder
                      src={customization.design.rendering.large}
                      width={customization.design.rendering.dimensions.large.width}
                      height={customization.design.rendering.dimensions.large.height}/>
                  }
                  onClose={() => Pervino.Cart.open()}
                  onOpen={() => Pervino.Cart.close()}
                >
                  <a className="uk-text-smaller">view</a>
                </ImageLightbox>
              </div>
              <a onClick={handleClick} className="uk-text-smaller">/change</a>
            </span>
    </div>
  )
}

const LineItemRemove = createReactClass({
  render() {
    if (this.props.removing) { return false; }

    const style = this.props.updating ? {'pointerEvents': 'none', opacity: '.7'} :  {};

    return <button className="uk-button uk-button-small uk-width-1-1" onClick={this.props.remove} style={style}>
        Remove
    </button>;
  }
});


export const LineItemQuantity = createReactClass({
  getInitialState() {
    return {
      updateSuccessful: true,
      quantity: this.props.initialQuantity,
      valid: true
    };
  },

  componentWillReceiveProps(nextProps) {
    return this.setState({quantity: nextProps.initialQuantity, valid: true});
  },

  update() {
    const req = this.props.update(this.state.quantity);

    req.done(() => {
      return this.setState({updateSuccessful: true});
    });
    return req.fail(() => {
      return this.setState({updateSuccessful: false});
    });
  },

  increment(event) {
    event.preventDefault();
    event.stopPropagation();
    return this.setState({quantity: this.state.quantity + 1});
  },

  decrement(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.quantity > 1) { return this.setState({quantity: this.state.quantity - 1}); }
  },

  handleChange(event) {
    return this.setState({quantity: event.value});
  },

  isValid(value) {
    return value && !isNaN(value);
  },

  render() {
    const buttonStyle = {
      lineHeight: "42px",
      width: "24px",
      fontSize: "13px",
      display: "inline-block",
      textAlign: "center",
      textDecoration: "none"
    };

    return <UIKitForm onSubmit={this.update}>
        <a onClick={this.decrement} href="javascript:void(0)" style={buttonStyle}>
            <i className="far fa-minus uk-text-muted"></i>
        </a>
        <UIKitInput type="number" min="1" value={this.state.quantity} onChange={this.handleChange}
                    isValid={this.isValid}
                    className="uk-text-center uk-input-plain" style={{width: '52px', outline: 'none'}}/>
        <a onClick={this.increment} href="javascript:void(0)" style={buttonStyle}>
            <i className="far fa-plus uk-text-muted"></i>
        </a>

        <div className="uk-flex uk-flex-middle uk-flex-center uk-margin-small-bottom"
             style={{height: "32px"}}>
            <LineItemQuantityUpdate key={status} updating={this.props.updating}
                                      modified={this.state.quantity != this.props.initialQuantity}
                                      success={this.state.updateSuccessful}/>
        </div>
    </UIKitForm>;
  }
});


const LineItemQuantityUpdate = createReactClass({
  getInitialState() {
    return {showUpdateStatus: false};
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.updating && !nextProps.updating) {
      this.setState({showUpdateStatus: true});
      return setTimeout((
        () => {
          if (!this.props.modified) { return this.setState({showUpdateStatus: false}); }
        }
      ), 1750);
    }
  },

  render() {
    if (this.props.updating) {
      return <div key="loading">
        <i className="far fa-cog uk-text-muted icon-spin"></i>
       </div>;
    } else if (this.props.modified) {
      return <button type="submit" className="uk-button uk-button-small uk-button-primary uk-width-1-1">
          Update
        </button>;
    } else {
      let icon;
      if (this.state.showUpdateStatus && this.props.success) {
        return icon = <div key="success" style={{color: "green"}}><i className="far fa-check icon-check"></i></div>;
      } else {
        icon = false;

        return <ReactTransitionGroup.CSSTransitionGroup transitionName="fade" transitionEnter={false} transitionLeaveTimeout={300}>
          {icon}
         </ReactTransitionGroup.CSSTransitionGroup>;
      }
    }
  }
});


const LineItemImage = createReactClass({
  render() {
    const item = this.props.item.attributes;
    const {
      customizations
    } = this.props.item;

    const virtualProofableCustomization = _.find(customizations, customization => customization.attributes.virtual_proof);
    if (virtualProofableCustomization) {
      return <LoadableImage title="Digital rendering" largeUrl={virtualProofableCustomization.attributes.virtual_proof.large}
                      smallUrl={virtualProofableCustomization.attributes.virtual_proof.small}/>;
    } else if (item.variant.images && item.variant.images[0]) {
      return <LoadableImage largeUrl={item.variant.images[0].product_url}
                      smallUrl={item.variant.images[0].large_url}/>;
    } else if (item.product_image_url) {
      return <LoadableImage largeUrl={item.product_image_url}
                      smallUrl={item.product_image_url}/>;
    } else {
      return <div className='uk-margin-medium-top-important uk-text-muted uk-text-smaller'>
          No image available
      </div>;
    }
  }
});


var LoadableImage = createReactClass({
  getInitialState() {
    return {loaded: false};
  },

  onImageLoad() {
    this.setState({loaded: true});
    if (this.props.onLoad) { return this.props.onLoad(); }
  },

  componentWillMount() {
    this.img = new window.Image();
    this.img.onload = this.onImageLoad;
    return this.img.src = this.props.smallUrl;
  },

  componentWillUnmount() {
    return this.img.onload = null;
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.smallUrl !== this.props.smallUrl) {
      return this.setState({loaded: false}, () => {
        this.img = new window.Image();
        this.img.onload = this.onImageLoad;
        return this.img.src = this.props.smallUrl;
      });
    }
  },

  render() {
    if (this.state.loaded) {
      return <div className="uk-animation-fade">
          <UIKitLightbox href={this.props.largeUrl} options={"{group: 'line-items'}"}
                         data-lightbox-type="image" className="uk-link-clear">
              <img src={this.props.smallUrl} style={{padding: "0 9ukpx", maxHeight: "260px"}}/>

              <div className="uk-margin-small-top uk-text-small uk-text-muted">
                  Click to enlarge
              </div>
          </UIKitLightbox>
      </div>;
    } else {
      return <div className="uk-margin-large-top">
          <div className="loading-wine-medium uk-align-center"></div>
      </div>;
    }
  }
});
