import React, { useState, useEffect, forwardRef } from "react"
import classNames from "classnames"

const ImageWithPlaceholder = forwardRef(({ src, width, height, className}, ref) => {
  const [imgUrl, setImgUrl] = useState(null)

  const onImageLoad = () => {
    setImgUrl(src)
  }

  useEffect(() => {
    let img = new window.Image()
    img.onload = onImageLoad
    img.src = src
  })

  return (
    <div className={classNames("image-placeholder", className)}
      style={{aspectRatio: `${width}/${height}`}}
      ref={ref}>
      <img src={imgUrl} loading="lazy" height={height} width={width}/>
      <div className={classNames("loading-text", {"fade-out": imgUrl})}>
        <p>Loading</p>
      </div>
    </div>
  )
})

export default ImageWithPlaceholder;
