import Cart from "../frontend/components/Cart";
import { OrderComponent } from "../frontend/application/Order";

(() => {
  let component = new OrderComponent(Cart, null, $("#cart-react-hook")[0]);

  PubSub.subscribe('app.order.updated', function (message, data) {
    component.setOrder(data.order);
  });
})();
